<template>
    <div>
        <app-table
            caption="Orders"
            :columns="columns"
            :actions="tableActions"
            url="orders"
            ref="table"
            :params="tableParams"
        ></app-table>
    </div>
</template>

<script>
import AppTable from '@/components/Table.vue';

export default {
    components: {
        AppTable
    },
    data() {
        return {
            columns: [
                {
                    text: 'Title',
                    value: 'title'
                },
                {
                    text: 'Description',
                    value: 'description'
                },
                {
                    text: 'PO',
                    value: 'purchase_order',
                    width: 120
                },
                {
                    text: 'Lifecycle',
                    value: 'lifecycle',
                    width: 120
                },
                {
                    text: 'Status',
                    value: 'status',
                    width: 100
                }
            ],
            tableParams: {
                query: {
                    order: ['id', 'title', 'purchase_order', 'description', 'lifecycle', 'status']
                }
            },
            tableActions: []
        };
    }
};
</script>
